import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout3';
import SEO from '../components/seo';
import Citysetting1 from '../components/citysetting/citysetting1';
import Citysetting2 from '../components/citysetting/citysetting2';
import Cityset from '../containers/cityset';
import Datalist from '../components/citysetting/citylist';
import Styles from './fsaas.module.css';


const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};



const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  const [value2, setValue2] = useStateWithLocalStorage(
    'cityset2'
  );
 
  const onChange = event => setValue(event.target.value);
  const onChange2 = event => setValue2(event.target.value2);
 
  return (
    <Layout>
            <SEO
        title="都市の設定"
        description="都市の設定画面です"
      />




<div className={Styles.container}>
    <header className={Styles.header}>
    <h1>はじめてのSAAS</h1>
    </header>
    <main className={Styles.main}>

      <div class="text-input">
          <input
            value={value}
            type="text"
            id="input1"
            placeholder="入力すると絞り込めます"
            onChange={onChange} 
            autocomplete="on"
            list="keywords"
          />
          <label for="input1">入力して選択</label>
          <Datalist />
        </div>
        <div class="text-input">
          <input
            value={value2}
            type="text"
            id="input1"
            placeholder="入力すると絞り込めます"
            onChange={onChange2} 
            autocomplete="on"
            list="keywords"
          />
          <label for="input1">入力して選択</label>
          <Datalist />
        </div>

    </main>
    <aside className={Styles.sidebar}>
      <p>SAASは地域の状況を可視化して分析できます。</p>
      <p>
      ２回目以降の方は、右から好きな都市を選択して、都市構造を分析できます。</p>
    </aside>
    <box11 className={Styles.box11}>
      <img src="/gamenimage.png" alt="image01" style={{maxWidth:"100%"}}/>

    </box11>
    <box12 className={Styles.box12}>
1. SAASでは、左側に解説と凡例、右側に地域の状況を可視化した図が配置されています。<br/>
<br/>
凡例を確認しながら、地図を操作して地域の状況を確認することができます。
<br/><br/>
    </box12>


    <box21 className={Styles.box21}>
2. まずはじめに、凡例を確認します。高さは人口、色は昼夜間人口比となっていることがわかります。<br/><br/>
昼夜間人口比とは、昼間と夜の人口の比率のことで、昼/夜で計算します。すなわち大きいほど昼間の人口が多いことを示します。<br/>
<br/>
高い＝人口が多い<br/>
低い＝人口が少ない<br/>
赤い＝昼間の人口が夜より多い<br/>
青い＝昼間の人口が夜より少ない<br/>
<br/>
最初のうちは、凡例と地図を交互に見ながら、読み取り方に慣れていきましょう。</box21>

    <box22 className={Styles.box22}>
      <img src="/hanreirei.png" alt="image01" style={{maxWidth:"100%"}}/>

    </box22>

    <box31 className={Styles.box31}>
2. 地図とグラフを確認します。
<br/>
SAASでは、地図上に人口や産業などのグラフが３次元で可視化されています。
<br/>
以下の操作が可能です。
<br/>
HOME：初期視点に戻る<br/>
PIN：中心を固定して回転する<br/>
Full：全画面表示<br/>
Map：同じ場所をGoogleMapで開く<br/>
Earth：可視化データをダウンロードする（GoogleEarthProで開けます）<br/>
<br/>
まずは、PINを押して読み取り、HOMEを押して元の位置に戻ってみましょう。<br/>
地図は直接自由に動かすこともできます。右側のコントロールパネルでも操作できます。

</box31>

    <box32 className={Styles.box32}>
      <img src="/cesiumgamen.png" alt="image01" style={{maxWidth:"100%"}}/>

    </box32>








    <footer className={Styles.footer}>
      

    さあ、それではSAASを使って地域を探究していきましょう。<br/>
      <Link to="/18/sogo">1.人口</Link><br/>
      <Link to="/106/sogo">2.商業</Link><br/>
      <Link to="/9014/sogo">3.交通</Link><br/>
      <Link to="/9203/sogo">4.防災</Link><br/>
      <Link to="/9019/sogo">5.防災</Link><br/>


    </footer>
</div>














    </Layout>
  );
};

export default App;